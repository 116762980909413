<template>
  <div>
    <div>
      <el-table
        :data="tableData"
        height="60vh"
        style="width: 100%"
        v-loading="loading"
      >
        <el-table-column
          v-for="(val, key) in tableTitle"
          :key="key"
          :prop="key"
          :label="val"
          :width="tableWidth.val"
        />
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @size-change="changeSize"
        @current-change="changeCurrentPage"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      tableTitle: {
        code: "Code",
        ip: "IP",
        os: "OS",
        start_time: "CreateTime",
        referer: "Referer",
        province: "Province",
        city: "City",
        is_new: "IsNew",
        browser_name: "BrowserName",
        device_type: "DeviceType",
        uv_count: "UV",
        pv_count: "PV",
      },
      tableWidth: {
        start_time: "200px",
      },
      loading: false,
    };
  },
  computed: {
    ...mapState({
      tableData: (state) => state.Data.pageRecordList,
      pageSize: (state) => state.Data.pageSize,
      total: (state) => state.Data.total,
      totalPage: (state) => state.Data.totalPage,
      currentPage: (state) => state.Data.currentPage,
      code: (state) => state.Data.code,
    }),
  },
  methods: {
    pageRecord() {
      this.$store.dispatch("setPageRecord");
    },
    changeSize(val) {
      this.$store.dispatch("changeSize", val);
    },
    changeCurrentPage(val) {
      this.$store.dispatch("changeCurrentPage", val);
    },
  },
  created() {
    this.pageRecord();
    setTimeout(() => {
      this.loading = false;
    }, 300);
  },
};
</script>

<style lang="scss"></style>
